import {toast, whenAvailable} from '../../../../../js/_utils';
(function (UX) {
    const shoppingCart = {
        selectors: {
            ITEM_CONTAINER: '.item-container',
            ITEM_TITLE: '.item-title',
        },
        overrides: function () {
            var errorHandler;
            let articleTitle = '';
            document.querySelectorAll(shoppingCart.selectors.ITEM_CONTAINER)?.forEach((item, index) => {
                const title = item?.querySelector('.item-title').innerText;
                item?.querySelector('.shopping-cart-remove-item a')?.setAttribute(
                    'aria-label',
                    `Remove from cart: ${title}`
                );
            });
            commerce.cart.buyingList.remove = function (itemId) {
                articleTitle = document
                    .querySelector(`[data-item-id='${itemId}']`)
                    .closest(shoppingCart.selectors.ITEM_CONTAINER)
                    .querySelector(shoppingCart.selectors.ITEM_TITLE).innerText;
                literatum.utils.send(
                    {
                        action: 'removeCartItem',
                        id: itemId,
                    },
                    commerce.cart.buyingList.sageRefresh,
                    errorHandler
                );
            };
            commerce.cart.buyingList.sageRefresh = function (cartInfo) {
                commerce.cart.buyingList.refresh(cartInfo);
                toast(true, `${articleTitle} has been removed from your shopping cart`);
            };
        },
    };
    $(document).ready(function () {
        whenAvailable('commerce', function () {
            whenAvailable('literatum', shoppingCart.overrides);
        });
    });
})(UX);
