import 'ui-core/basic/base/js/base';

// A base class for our components, it will make it easier to do some effect for all components at once

export default class Base {
    get selectors() {
        return {};
    }
    get classList() {
        return {};
    }
    get elements() {
        return {};
    }
    get texts() {
        return {};
    }

    constructor() {
        this._selectors = this.selectors;
        this._classList = this.classList;
        this._elements = this.elements;
        this._texts = this.texts;
    }

    initialize() {}

    addEventListeners() {}

    convertToArray(htmlCollection) {
        return Array.prototype.slice.call(htmlCollection);
    }

    stringToNode(string) {
        const tempParent = document.createElement('div');
        tempParent.innerHTML = string.trim();
        return tempParent.firstChild;
    }
}
