'use strict';

(function (UX) {
    const selectableDropdown = {
        DROPDOWN: '.dropdown-selectable',
        DROPDOWN_SINGLE_ITEM: '.dropdown-item',
        DROPDOWN_ITEM: '.dropdown-selectable .dropdown-item',
        DROPDOWN_TOGGLE: '.dropdown-toggle span, .tabs-toggle',
        DROPDOWN_TOGGLE_BUTTON: '.dropdown-toggle',
        DROPDOWN_FORM_CONTROL: '.dropdown-selectable--form-control',
        init: function () {
            this.control();
        },
        control: function () {
            $(document.body).on('click', this.DROPDOWN_ITEM, this.onClickHandler);
            $(document.body).on('keydown', this.DROPDOWN, this.onKeyDown);
            document.body.addEventListener('ready.selectable', this.onReadySelectable.bind(this));
        },
        onReadySelectable: function (e) {
            let sourceDropdown = document.getElementById(e.detail.sourceId).closest(selectableDropdown.DROPDOWN);
            $(sourceDropdown).on('shown.bs.dropdown', function (e) {
                $(this).find('[aria-selected]').focus();
            });
        },
        onClickHandler: function (e) {
            let href = this.getAttribute('href');
            if (href === '#' || href === '') {
                e.preventDefault();
            }
            const $this = $(this);
            const $toggle = $this.closest(selectableDropdown.DROPDOWN).find(selectableDropdown.DROPDOWN_TOGGLE);
            $toggle.text($this.get(0).dataset.label || $this.text());
            let additionalClasses = $this.get(0).dataset.class;
            if (additionalClasses) {
                $toggle.get(0).setAttribute('class', '');
                $toggle.get(0).classList.add(...additionalClasses.split(' '));
            }
            $this.siblings().removeClass('active');
            $this.siblings().attr('aria-selected', null);
            $this.addClass('active');
            $this.attr('aria-selected', 'true');

            const formControl = this.closest(selectableDropdown.DROPDOWN_FORM_CONTROL);
            if (formControl) {
                let input = formControl.querySelector('input');
                input.value = this.dataset.value;
                let dataAttribute = this.dataset;
                Object.keys(dataAttribute).forEach(key => {
                    input.dataset[key] = dataAttribute[key];
                });
                // input.setAttribute('value', this.dataset.value);
                $(input).change();
                formControl.querySelector(selectableDropdown.DROPDOWN_TOGGLE_BUTTON).focus();
            }
        },
        onKeyDown: function (e) {
            let keyCode = e.keyCode;
            if (keyCode >= 65 && keyCode <= 130) {
                let keyValue = e.key.toLowerCase();
                let options = document.querySelectorAll('.dropdown-selectable.show .dropdown-item');
                let BreakException = {};
                if (options) {
                    try {
                        options.forEach(function (option) {
                            let char = option.innerText.toLowerCase().charAt(0);
                            if (keyValue === char) {
                                option.focus();
                                throw BreakException;
                            }
                        });
                    } catch (e) {
                        if (e !== BreakException) throw e;
                    }
                }
            }
        },
    };
    UX.selectableDropdown = selectableDropdown;
})(UX);
