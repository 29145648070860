'use strict';
import Base from '../../../js/_base';
import PasswordFormatValidation from './_password-format-validation';
import PasswordEqualityValidation from './_password-equality-validation';
import RequiredFieldsValidation from './_required-fields-validation';

export default class ResetPassword extends Base {
    constructor() {
        super();
        this.form = document.querySelector(this._selectors.FORM);
        if (this.form) {
            this.initialize();
        }
    }

    get selectors() {
        return {
            FORM: '.reset-password__form',
            DROPDOWN_TOGGLE: '.dropdown-toggle',
            FORM_GROUP: '.form-group',
            FORM_CONTROL: '.form-control',
            PASSWORD_INPUT_FORMAT_VALIDATE: '.js-format-validate',
        };
    }

    initialize() {
        super.initialize();
        this.requiredFieldsValidation = new RequiredFieldsValidation(this.form);
        this.passwordFormatValidation = new PasswordFormatValidation(this.form);
        this.passwordEqualityValidation = new PasswordEqualityValidation(this.form);
        this.control();
    }

    control() {
        this.form.setAttribute('novalidate', 'true');
        this.form.addEventListener('submit', this.onSubmit.bind(this));
    }
    onSubmit(event) {
        let target = event.target
            .closest(this._selectors.FORM)
            .querySelector(this._selectors.PASSWORD_INPUT_FORMAT_VALIDATE);
        let isProperFormatted = this.checkPasswordFormat(target);
        let isEqual = this.checkPasswordEquality();

        if (isProperFormatted && isEqual) {
            return true;
            // this.form.submit();
        } else {
            event.preventDefault();
            this.focusFirstInvalid();
            this.form.scrollIntoView();
        }
    }
    //TODO: all copies of focusFirstInvalid methods should be moved into one module with helpers
    focusFirstInvalid() {
        let formGroup = this.form.querySelector('.form-group.is-invalid');
        let elToFocus =
            formGroup.querySelector(this._selectors.DROPDOWN_TOGGLE) ||
            formGroup.querySelector(this._selectors.FORM_CONTROL);
        let elMessage = formGroup.querySelector('.message');
        elMessage.setAttribute('aria-live', 'assertive');
        elToFocus?.focus();
    }
    checkPasswordFormat(target) {
        return this.passwordFormatValidation.checkPasswordFormat(target, true);
    }
    checkPasswordEquality() {
        return this.passwordEqualityValidation.checkPasswordEquality();
    }
}
