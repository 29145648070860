'use strict';
import Base from '../../../js/_base';

const PHONE_REGEX = '^[0-9]+$';

export default class PhoneFormatValidation extends Base {
    constructor(form) {
        super();
        this.form = form;
        this.isValid = true;
    }

    get selectors() {
        return {
            FORM: 'form',
            FORM_GROUP: '.form-group',
            phone_INPUT: '[type="tel"]',
            MESSAGE: '.message',
        };
    }

    get classList() {
        return {
            DISPLAY_NONE: 'd-none',
            DISPLAY_FLEX: 'd-flex',
            IS_VALID: 'is-valid',
            IS_INVALID: 'is-invalid',
        };
    }

    get texts() {
        return {
            MESSAGE: 'This is not a valid mobile number.',
        };
    }

    initialize() {
        super.initialize();
    }

    checkPhoneFormat() {
        this.isValid = true;
        let phoneInputs = this.form.querySelectorAll(this.selectors.phone_INPUT);
        let phone = '',
            message = '',
            input = null,
            formGroup = null;
        if (phoneInputs.length > 0) {
            phoneInputs.forEach(field => {
                formGroup = field.closest(this._selectors.FORM_GROUP);
                field.setAttribute('aria-invalid', 'false');
                input = formGroup?.querySelector('[aria-describedby]');
                field.classList.remove(this._classList.IS_INVALID);
                formGroup.classList.remove(this._classList.IS_INVALID);
                formGroup.querySelector(this._selectors.MESSAGE)?.remove();

                phone = field.value;
                if (phone) {
                    let regexp = new RegExp(PHONE_REGEX, 'gm');
                    if (!regexp.test(phone)) {
                        field.setAttribute('aria-invalid', 'true');
                        field.classList.add(this._classList.IS_INVALID);
                        formGroup.classList.add(this._classList.IS_INVALID);
                        message = this._texts.MESSAGE;
                        field.parentElement?.insertAdjacentHTML(
                            'beforebegin',
                            `<div class="message error">${message}</div>`
                        );
                        this.isValid = false;
                        let errorMessage = formGroup?.querySelector('.message.error');
                        errorMessage?.setAttribute('id', input?.getAttribute('aria-describedby'));
                    }
                }
            });
        }
        return this.isValid;
    }
}
