(function (UX) {
    let doLogin = {
        widget: null,
        forms: null,
        activeForm: null,
        SERVICE_NAME: '/action/doLogin',
        selectors: {
            CONTAINER: 'form[action="/action/doLogin"]',
            USEAJAX_INPUT: '[name="submitViaAJAX"]',
            USERNAME_INPUT: '[name="login"]',
            PASSWORD_INPUT: '[name="password"], [name="password1"]',
            REMEMBER_INPUT: '[name="remember"]',
            ERROR_EL: '.useAJAX_SSO_error',
            ERROR: '.error',
            INPUTS: 'input:not([type="checkbox"]):not([type="hidden"])',
            AntiForgeryToken: 'input[name="anti-forgery-token"]',
        },
        classes: {
            D_NONE: 'd-none',
            IS_INVALID: 'is-invalid',
        },
        messages: {
            SUCCESS_FALSE_MESSAGE:
                'The email address and/or password entered does not match our records, please check and try again.',
        },
        init: function () {
            this.widget = document.querySelectorAll(this.selectors.CONTAINER);
            this.forms = document.querySelectorAll(this.selectors.CONTAINER);
            if (this.forms && this.forms.length > 0) {
                this.control();
            }
        },
        control: function () {
            this.forms.forEach(function (form) {
                doLogin.activeForm = form;
                doLogin.activeForm.addEventListener('submit', this.processSubmitForm);
                let els = this.getInputs();
                els.forEach(el => el.addEventListener('change', this.onChangeHandler), this);
            }, this);
        },
        onChangeHandler: function (e) {
            this.classList.remove(doLogin.classes.IS_INVALID);
            this.removeAttribute('aria-invalid');
            //doLogin.activeForm.querySelector(doLogin.selectors.ERROR_EL)?.classList.add('d-none');
        },
        onSubmitHandler: function (e) {
            let els = doLogin.getInputs();
            els.forEach(el => {
                el.classList.remove(doLogin.classes.IS_INVALID);
                el.removeAttribute('aria-invalid');
            });
            doLogin.activeForm.querySelector(doLogin.selectors.ERROR)?.classList.add(doLogin.classes.D_NONE);
        },
        processSubmitForm: function (e) {
            doLogin.activeForm = this;
            doLogin.onSubmitHandler();
            const useAjax = doLogin.activeForm.querySelector(doLogin.selectors.USEAJAX_INPUT).value;

            if (!useAjax) {
                return true;
            } else {
                e.preventDefault();
                let login = doLogin.activeForm.querySelector(doLogin.selectors.USERNAME_INPUT).value;
                let password = doLogin.activeForm.querySelector(doLogin.selectors.PASSWORD_INPUT).value;
                let remember = doLogin.activeForm.querySelector(doLogin.selectors.REMEMBER_INPUT).checked;
                let antiForgeryToken = doLogin.activeForm.querySelector(doLogin.selectors.AntiForgeryToken).value;
                $.ajax({
                    url: doLogin.SERVICE_NAME,
                    type: 'POST',
                    data: {
                        login: login,
                        password: password,
                        remember: remember,
                        'anti-forgery-token': antiForgeryToken,
                    },
                    success: doLogin.handleSuccessRequest,
                    error: doLogin.handleBadRequest,
                });
            }
        },
        handleSuccessRequest: function (response, textStatus, jqXHR) {
            if (response) {
                if (response.success === true || response.success === 'true') {
                    location.reload();
                } else {
                    let els = doLogin.getInputs();
                    els.forEach(el => {
                        el.classList.add('is-invalid');
                        el.setAttribute('aria-invalid', 'true');
                    });
                    let errorEl = doLogin.activeForm.querySelector(doLogin.selectors.ERROR_EL);
                    let errorTextEl = errorEl.querySelector(doLogin.selectors.ERROR);
                    errorTextEl.innerText = doLogin.messages.SUCCESS_FALSE_MESSAGE;

                    if (response.notVerified) {
                        errorTextEl.innerText = response.notVerified;
                    }
                    if (response.emptyEmail) {
                        errorTextEl.innerText = response.emptyEmail;
                    }
                    if (response.emptyPassword) {
                        errorTextEl.innerText = response.emptyPassword;
                    }
                    errorEl.classList.remove(doLogin.classes.D_NONE);
                    errorTextEl.classList.remove(doLogin.classes.D_NONE);
                }
            }
        },
        handleBadRequest: function (jqXHR, status, error) {
            // Some error management should be added...
        },
        getInputs: function () {
            return doLogin.activeForm.querySelectorAll(doLogin.selectors.INPUTS);
        },
    };

    UX.doLogin = doLogin;
})(UX);
