import 'ui-theme/components/issue-item/js/issue-item';

(function ($, UX) {
    const {issueItem} = UX;
    const {truncateOptions} = issueItem;
    issueItem.elements = issueItem.elements || {};
    issueItem.selectors = issueItem.selectors || {};

    issueItem.selectors = {
        ...issueItem.selectors,
        AUTHORS: '.issue-item__authors .loa',
        DATA_ID_MORE_AUTHORS: '[data-data-id-more-authors]',
        MORE_AUTHORS: `.read-more`,
        viewMoreBtn: '.bs-accordion__content .show-more',
        collapsedItemsList: '.publication-list .collapse',
        unselectedTabPanes: '.tab__content .tab__pane',
        teaserContribes: '.teaser__contribs',
    };

    issueItem.elements = {
        ...issueItem.elements,
        viewMoreBtn: document.querySelector(issueItem.selectors.viewMoreBtn),
        collapsedItemsList: document.querySelector(issueItem.selectors.collapsedItemsList),
        unselectedTabPanes: document.querySelectorAll(issueItem.selectors.unselectedTabPanes),
    };

    issueItem.seeMoreText = 'View all';
    issueItem.seeLessText = 'Hide all';

    issueItem.controls = function () {
        $(window).on('resized orientationchange', issueItem.onChangedHandler);
        $(document).on('accordion:expandedSingle', issueItem.onChangedHandler);
        $(document).on('accordion:expandedAll', issueItem.onChangedHandler);
        $('.accordion-tabbed__control, .expand-all').on('click', issueItem.onChangedHandler);
        this.setObservers();
    };
    issueItem.onChangedHandler = function () {
        setTimeout(() => {
            issueItem.truncate();
        }, 250);
    };
    issueItem.afterTruncated = function ($el) {
        let el = $el[0];
        if (el) {
            const dataId = el.closest(issueItem.selectors.DATA_ID_MORE_AUTHORS)?.dataset.dataIdMoreAuthors;
            let link = el.querySelector(issueItem.selectors.MORE_AUTHORS);
            if (link) {
                link.dataset.id = dataId;
            }
        }
    };
    const options = {
        lines: 1,
        lessLinkEllipsis: false,
        vPort: 'screen-sm',
        seeLessIcon: 'icon-arrow_up',
        seeMoreIcon: 'icon-arrow_down',
        seeMoreIconMobile: 'icon-arrow_down',
        seeMoreHtml: `<span class="ellipsis">[...]</span> <span class="read-more__label">${issueItem.seeMoreText} <i class="icon-arrow_down" aria-hidden="true"></i></span>`,
        onComplete: issueItem.afterTruncated,
    };

    issueItem.setObservers = function () {
        this.observeForChange(this.elements.collapsedItemsList, this.truncate);
        this.elements.unselectedTabPanes.forEach(pane => this.observeForChange(pane, this.truncate));
    };

    issueItem.observeForChange = function (targetNode, callback, config = {attributes: true}) {
        const isTeaserContribes = targetNode?.querySelector(this.selectors.teaserContribes);
        const isElementHidden = targetNode && window.getComputedStyle(targetNode).display === 'none';

        if (!callback || !isTeaserContribes) return;
        const observer = new MutationObserver(() => {
            if (!isElementHidden) return;
            callback();
        });
        observer.observe(targetNode, config);
    };

    issueItem.truncateOptions = {...truncateOptions, ...options};
    issueItem.setCaptions = new Function();

    UX.issueItem = issueItem;
})($, UX);
