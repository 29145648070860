(function () {
    let ariaLive = {
        ariaLiveElements: null,
        selectors: {
            ARIA_LIVE: '.aria-live',
        },
        classList: {
            DISPLAY_NONE: 'd-none',
        },
        htmlMarkups: {
            error: `<p class='message error'>{{message}}</p>`,
            success: `<div class='success-message my-3'><i class='icon-check-circle-outline text-success' aria-hidden='true'></i><span>{{message}}</span></div>`,
        },
        init: function () {
            this.ariaLiveElements = document.querySelectorAll(this.selectors.ARIA_LIVE);
            if (this.ariaLiveElements.length > 0) {
                this.control();
            }
        },
        control: function () {
            [...this.ariaLiveElements].forEach(this.showMessage, this);
        },
        showMessage: function (ariaLiveEl) {
            let type = ariaLiveEl.dataset.type,
                message = ariaLiveEl.dataset.message,
                input = ariaLiveEl?.parentNode.querySelector('input[aria-describedby]');

            window.setTimeout(() => {
                ariaLiveEl.insertAdjacentHTML('afterbegin', this.htmlMarkups[type].replace('{{message}}', message));
                ariaLiveEl
                    ?.querySelector('.message.error')
                    ?.setAttribute('id', input?.getAttribute('aria-describedby'));
            }, 300);
        },
    };
    UX.ariaLive = ariaLive; // add to global namespace
})();
