(function (UX) {
    let autoDismiss = {
        selectors: {
            AUTO_DISMISS: '[data-autodismiss]',
        },
        classes: {
            DISPLAY_NONE: 'd-none',
        },
        HIDE_INTERVAL: 3000,
        init: function () {
            this.alerts = document.querySelectorAll(this.selectors.AUTO_DISMISS);
            if (this.alerts && this.alerts.length > 0) {
                this.alerts.forEach(alert => {
                    document.querySelector('body').append(alert);
                    alert.classList.remove(this.classes.DISPLAY_NONE);
                    let timeout = alert.dataset.autodismiss || this.HIDE_INTERVAL;
                    window.setTimeout(() => alert.remove(), +timeout);
                }, this);
            }
        },
    };

    UX.autoDismiss = autoDismiss;
})(UX);
