'use strict';
import 'ui-core/widgets/raa/js/registration';
import Base from '../../../js/_base';
import PasswordFormatValidation from './_password-format-validation';
import EmailFormatValidation from './_email-format-validation';
import PasswordEqualityValidation from './_password-equality-validation';
import ExcludedCharacters from './_excluded-characters';
import RequiredFieldsValidation from '../../raa/js/_required-fields-validation';

export default class Registration extends Base {
    get selectors() {
        return {
            FORM: '[name="regForm"]',
            PASSWORD_INPUT: '[type="password"]',
            EMAIL_INPUT: '[type="email"]',
            SWITCHER: '.custom-control-input',
            PASSWORD_INPUT_FORMAT_VALIDATE: '.js-format-validate',
            REQUIRED_FIELD: '[required]',
            SUBMIT_BTN: '.btn-submit',
            FORM_GROUP: '.form-group',
            FORM_CONTROL: '.form-control',
            FORM_ROW: '.form-row',
            MESSAGE: '.message',
            LABEL: 'label',
            GLOBAL_ERROR: '.global-error',
        };
    }

    get classList() {
        return {
            DISPLAY_NONE: 'd-none',
            DISPLAY_FLEX: 'd-flex',
            IS_VALID: 'is-valid',
            IS_INVALID: 'is-invalid',
            ERROR: 'error',
            GLOBAL_ERROR: 'global-error',
        };
    }

    get texts() {
        return {
            REGISTRATION_CANNOT_BE_COMPLETED:
                'Your registration cannot be completed. Missing fields and other types of errors are highlighted in red.',
            FILL_FIELD: 'This required field was missing. Please enter your %form_label%.',
            ONLY_ALPHANUMERIC: 'Please use only alpha-numeric characters.',
            SELECT_OPTION: 'This required field was missing. Please select your %form_label% from the drop-down.',
            CONFIRM_TERMS:
                'Please, confirm that you have read and agreed to the Privacy Policy and the Terms and Conditions.',
            WRONG_EMAIL_FORMAT: 'Email has wrong format.',
        };
    }

    constructor() {
        super();
        this.form = document.querySelector(this._selectors.FORM);
        if (this.form) {
            this.form.setAttribute('novalidate', 'true');
            this.form.setAttribute('autocomplete', 'off');
            this.passwordInputs = this.form.querySelectorAll(this._selectors.PASSWORD_INPUT);
            this.submitBtn = this.form.querySelector(this._selectors.SUBMIT_BTN);
            this.initialize();
        }
    }

    initialize() {
        super.initialize();
        this.requiredFieldsValidation = new RequiredFieldsValidation(this.form);
        this.passwordFormatValidation = new PasswordFormatValidation(this.form);
        this.passwordEqualityValidation = new PasswordEqualityValidation(this.form);
        this.emailFormatValidation = new EmailFormatValidation(this.form);
        this.excludedCharacters = new ExcludedCharacters(this.form);
        this.control();
    }

    control() {
        if (this.form) {
            const switcher = this.form.querySelector(this._selectors.SWITCHER);
            if (switcher) {
                switcher.addEventListener('click', this.onSwitcherClick);
            }
            this.form.addEventListener('submit', this.onSubmitHandler.bind(this));
        }
    }

    onSubmitHandler(event) {
        let isRequiredFilled = this.requiredFieldsValidation.checkRequired();
        let isProperFormatted = this.checkPasswordFormat(
            event.target.closest(this._selectors.FORM).querySelector(this._selectors.PASSWORD_INPUT_FORMAT_VALIDATE)
        );
        let isEqual = true; //this.passwordEqualityValidation.checkPasswordEquality();
        let isMail = this.emailFormatValidation.checkEmailFormat();
        let isAllowed = this.excludedCharacters.checkExcludedCharacters();

        if (isRequiredFilled && isProperFormatted && isEqual && isMail && isAllowed) {
            this.addData('submit', 'submit');
            return true;
        } else {
            event.preventDefault();
            this.form.insertAdjacentElement(
                'afterbegin',
                this.createErrorMessage(this._texts.REGISTRATION_CANNOT_BE_COMPLETED)
            );
            this.focusFirstInvalid();
            this.form.scrollIntoView();
        }
        return false;
    }
    createErrorMessage(text) {
        let el = document.querySelector(this._selectors.GLOBAL_ERROR);
        if (!el) {
            el = document.createElement('div');
            el.classList.add(this._classList.ERROR);
            el.classList.add(this._classList.GLOBAL_ERROR);
            el.setAttribute('aria-live', 'assertive');
        }
        el.innerText = text;
        return el;
    }
    //TODO: all copies of focusFirstInvalid methods should be moved into one module with helpers
    focusFirstInvalid() {
        let formGroup = this.form.querySelector('.form-group.is-invalid');
        let elToFocus = formGroup.querySelector('select') || formGroup.querySelector(this._selectors.FORM_CONTROL);
        elToFocus?.focus();
    }

    checkPasswordFormat(target) {
        return this.passwordFormatValidation.checkPasswordFormat(target, true);
    }

    //TODO: make it shareable - move it to utilities?
    addData(name, value = '') {
        let el = this.form.querySelector('[name="' + name + '"]');
        if (el) {
            // value = el.getAttribute('value');
            el.removeAttribute('name');
        }
        let input = document.createElement('input');
        input.setAttribute('name', name);
        input.setAttribute('value', value);
        input.setAttribute('type', 'hidden');
        this.form.appendChild(input);
    }

    onSwitcherClick() {
        let $this = $(this);
        if ($this.is(':checked')) {
            $this.attr('aria-checked', 'true');
        } else {
            $this.attr('aria-checked', 'false');
        }
    }
}
