'use strict';
import Base from '../../../js/_base';

const EXCLUDED_CHARACTERS_REGEX = /([`¬\*\^£\$%=<>{}\[\]_…\/\\\|])/;

export default class ExcludedCharacters extends Base {
    constructor(form) {
        super();
        this.form = form;
        this.isValid = true;
    }

    get selectors() {
        return {
            FORM: 'form',
            FORM_GROUP: '.form-group',
            TEXT_INPUT: '[type="text"], [type="search"]',
        };
    }

    get classList() {
        return {
            DISPLAY_NONE: 'd-none',
            DISPLAY_FLEX: 'd-flex',
            IS_VALID: 'is-valid',
            IS_INVALID: 'is-invalid',
            FORM_PASSWORD: 'form-password',
        };
    }

    get texts() {
        return {
            MESSAGE: 'Characters ` ¬ * ^ £ $ % = <> {}[] _ … / \\ | are not allowed.',
        };
    }

    initialize() {
        super.initialize();
    }

    checkExcludedCharacters() {
        this.isValid = true;
        let textInputs = this.form.querySelectorAll(this.selectors.TEXT_INPUT);
        let text = '',
            message = '',
            input = null,
            formGroup = null;
        if (textInputs.length > 0) {
            textInputs.forEach(field => {
                let isPassword = field.parentElement.classList.contains(this._classList.FORM_PASSWORD);
                text = field.value;
                if (!!text && !isPassword) {
                    formGroup = field.closest(this._selectors.FORM_GROUP);
                    field.setAttribute('aria-invalid', 'false');
                    field.classList.remove(this._classList.IS_INVALID);
                    formGroup.classList.remove(this._classList.IS_INVALID);
                    input = formGroup?.querySelector('[aria-describedby]');

                    let regexp = new RegExp(EXCLUDED_CHARACTERS_REGEX, 'gm');
                    if (regexp.test(text)) {
                        field.setAttribute('aria-invalid', 'true');
                        field.classList.add(this._classList.IS_INVALID);
                        formGroup.classList.add(this._classList.IS_INVALID);
                        message = this._texts.MESSAGE;
                        field.insertAdjacentHTML('beforebegin', `<div class="message error">${message}</div>`);
                        this.isValid = false;
                        let errorMessage = formGroup?.querySelector('.message.error');
                        errorMessage?.setAttribute('id', input?.getAttribute('aria-describedby'));
                    }
                }
            });
        }
        return this.isValid;
    }
}
