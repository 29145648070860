'use strict';
import Base from '../../../js/_base';
import {escapeHtml} from '../../../basic/utils/js/utils';

export default class RequiredFieldsValidation extends Base {
    ERROR_MESSAGE_TEMPLATE = '<div><div class="message error">%message%</div></div>';
    constructor(form) {
        super();
        this.form = form;
        this.isValid = true;
    }

    get selectors() {
        return {
            FORM: 'form',
            FORM_GROUP: '.form-group',
            FORM_PASSWORD: '.form-password',
            REQUIRED_FIELD: '[required]',
            FORM_CONTROL: '.form-control',
            DROPDOWN: '.dropdown-selectable',
            DROPDOWN_TOGGLE: '.dropdown-toggle',
            FORM_ROW: '.form-row',
            MESSAGE: '.message',
            ERROR: '.message.error',
            LABEL: 'label',
        };
    }

    get classList() {
        return {
            DISPLAY_NONE: 'd-none',
            DISPLAY_FLEX: 'd-flex',
            IS_VALID: 'is-valid',
            IS_INVALID: 'is-invalid',
        };
    }

    get texts() {
        return {
            FILL_FIELD: 'This required field was missing. Please enter your %form_label%.',
            ONLY_ALPHANUMERIC: 'Please use only alpha-numeric characters.',
            SELECT_OPTION: 'This required field was missing. Please select your %form_label% from the drop-down.',
            CONFIRM_TERMS:
                'Please, confirm that you have read and agreed to the Privacy Policy and the Terms and Conditions.',
            WRONG_EMAIL_FORMAT: 'Email has wrong format.',
        };
    }

    initialize() {
        super.initialize();
    }

    checkRequired() {
        this.isValid = true;
        let requiredFields = this.form.querySelectorAll(this._selectors.REQUIRED_FIELD);
        requiredFields.forEach((field, index) => {
            this.setValid(field);
            if (!field.value || field.value === '' || (field.type === 'checkbox' && field.checked === false)) {
                this.setInvalid(field);
                this.isValid = false;
            }
        }, this);
        return this.isValid;
    }

    setValid(field) {
        let formGroup = field.closest(this._selectors.FORM_GROUP);
        field.setAttribute('aria-invalid', 'false');
        field.classList.remove(this._classList.IS_INVALID);
        formGroup?.classList.remove(this._classList.IS_INVALID);
        formGroup?.querySelector(this._selectors.ERROR)?.remove();
        field
            .closest(this._selectors.DROPDOWN)
            ?.querySelector(this._selectors.DROPDOWN_TOGGLE)
            ?.classList.remove(this._classList.IS_INVALID);
    }

    setInvalid(field) {
        field.setAttribute('aria-invalid', 'true');
        field.classList.add(this._classList.IS_INVALID);
        let formGroup = null,
            formRow = null,
            input = null,
            label = null,
            labelTxt = '',
            message = '';
        formGroup = field.closest(this._selectors.FORM_GROUP);
        label = formGroup?.querySelector(this._selectors.LABEL);
        labelTxt = label.innerText.replace(':', '');
        formGroup?.classList.add(this._classList.IS_INVALID), (input = formGroup?.querySelector('[aria-describedby]'));

        switch (field.name) {
            case 'terms.value':
                message = this.ERROR_MESSAGE_TEMPLATE.replace('%message%', escapeHtml(this._texts.CONFIRM_TERMS));
                formRow = field.closest(this._selectors.FORM_ROW);
                formRow?.insertAdjacentHTML('beforebegin', message);
                break;
            default:
                message = this.ERROR_MESSAGE_TEMPLATE.replace(
                    '%message%',
                    this._texts.FILL_FIELD.replace('%form_label%', escapeHtml(labelTxt))
                );
                label.insertAdjacentHTML('afterend', message);
        }
        let errorMessage = formGroup?.querySelector('.message.error');
        errorMessage?.setAttribute('id', input?.getAttribute('aria-describedby'));
    }
}
