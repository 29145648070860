(function (UX) {
    let copyToClipboard = {
        selectors: {
            COPY_BTN: '.js-copy-to-clipboard',
        },
        classes: {
            ALERT: ['alert', 'alert--floating'],
            ALERT_SUCCESFUL: 'alert--success',
        },
        ALERT_HIDE_INTERVAL: 4000,
        init: function () {
            let buttons = document.querySelectorAll(this.selectors.COPY_BTN);
            if (buttons && buttons.length > 0) {
                this.control(buttons);
            }
        },
        control: function (buttons) {
            for (let button of buttons) {
                button.addEventListener('click', this.onClickHandler);
            }
        },
        onClickHandler: function (e) {
            let text = '';
            const target = document.querySelector(this.dataset.rel);
            if (target) {
                if (['TEXTAREA', 'INPUT'].includes(target.tagName)) {
                    text = target.value;
                } else {
                    text = target.innerText;
                }
                copyToClipboard.copy(this, text);
            }
        },
        copy: function (btn, text) {
            if (!navigator.clipboard) {
                copyToClipboard.fallback(text);
                return;
            }
            navigator.clipboard
                .writeText(text)
                .then(function () {
                    copyToClipboard.alert(true, btn.dataset.success);
                })
                .catch(error => {
                    console.error('Oops, unable to copy', error);
                });
        },
        fallback: function (btn, text) {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            // Avoid scrolling to bottom
            textArea.style.top = '0';
            textArea.style.left = '0';
            textArea.style.position = 'fixed';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                let successful = document.execCommand('copy');
                if (successful) {
                    copyToClipboard.alert(true, btn.dataset.success);
                }
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        },
        // TODO: move to utilities...
        alert: function (status, msg) {
            let alert = document.createElement('div');
            alert.setAttribute('role', 'alert');
            copyToClipboard.classes.ALERT.forEach(c => {
                alert.classList.add(c);
            });
            alert.append(msg);
            if (status) {
                alert.classList.add(copyToClipboard.classes.ALERT_SUCCESFUL);
            }
            document.querySelector('body').appendChild(alert);
            window.setTimeout(() => alert.remove(), copyToClipboard.ALERT_HIDE_INTERVAL);
        },
    };
    UX.copyToClipboard = copyToClipboard;
})(UX);
