export default class Alphanumeric {
    selectors = {
        titleLetter: '.alphanumeric__content .alphanumeric__title',
        allTitle: '.alphanumeric__block .alphanumeric__title',
    };

    elements = {
        titleLetters: null,
        allTitle: null,
    };

    setElements() {
        this.elements.titleLetters = document.querySelectorAll(this.selectors.titleLetter);
        this.elements.allTitle = document.querySelector(this.selectors.allTitle);
    }

    constructor() {
        this.setElements();
        this.init();
    }

    init() {
        this.elements.titleLetters.forEach(title => {
            title.addEventListener('keydown', this.addListener.bind(this));
            title.addEventListener('click', this.addListener.bind(this));
        });
    }

    addListener(event) {
        if (event.keyCode === 13 || event.keyCode === 32) {
            event.preventDefault();
            if (event.target.classList.contains('is--selected')) {
                this.elements.allTitle.click();
            } else {
                event.target.click();
            }
        } else if (event.type === 'click') {
            event.preventDefault();
            if (event.target.classList.contains('is--selected')) {
                this.elements.allTitle.click();
            }
        }
    }
}
