'use strict';
import Base from '../../../js/_base';

export default class CreateSelectableDropdown extends Base {
    attributesList;
    selectedOption;
    parentEl;
    dropdownClasses;

    constructor() {
        super();
        this.selects = document.querySelectorAll(this._selectors.SELECT);
        if (this.selects) {
            this.selects.forEach(function (select) {
                this.initialize(select);
            }, this);
        }
    }

    get selectors() {
        return {
            SELECT: '.js--selectable',
        };
    }

    get classList() {
        return {
            DISPLAY_NONE: 'd-none',
            DROPDOWN: 'dropdown',
            DROPDOWN_SELECTABLE: 'dropdown-selectable',
            DROPDOWN_SELECTABLE_CONTROL: 'dropdown-selectable--form-control',
            BTN: 'btn',
            DROPDOWN_MENU: 'dropdown-menu',
            DROPDOWN_ITEM: 'dropdown-item',
            DROPDOWN_TOGGLE: 'dropdown-toggle',
            CARET: 'icon-arrow_down',
            ACTIVE: 'active',
            REQUIRED: 'required',
        };
    }

    initialize(originalSelect) {
        super.initialize();
        this.parentEl = originalSelect.parentElement;
        this.selectedOption = originalSelect.options[originalSelect.selectedIndex];
        this.attributesList = ['name', 'id', 'required', 'disabled', 'aria-describedby'];
        for (let attr of this.attributesList) {
            originalSelect[attr] = originalSelect.getAttribute(attr);
        }
        originalSelect['aria-describedby'] =
            originalSelect['aria-describedby'] || this.parentEl.querySelector('label')?.getAttribute('id');
        // Create dropdown
        const dropdownId = 'dropdown' + Number.parseInt(Math.random() * 1000);
        const dropdown = document.createElement('div');
        this.dropdownClasses = [
            this._classList.DROPDOWN,
            this._classList.DROPDOWN_SELECTABLE,
            this._classList.DROPDOWN_SELECTABLE_CONTROL,
        ];
        if (originalSelect['required']) this.dropdownClasses.push(this._classList.REQUIRED);
        dropdown.classList.add(...this.dropdownClasses);

        // Create input element and set its attributes
        const input = document.createElement('input');
        input.setAttribute('type', originalSelect['required'] ? 'text' : 'hidden');
        input.setAttribute('id', originalSelect['id']);
        input.setAttribute('name', originalSelect['name']);
        input.setAttribute('value', this.selectedOption.value);
        input.setAttribute('tabindex', '-1');
        if (originalSelect['required']) {
            input.setAttribute('required', 'true');
            input.setAttribute('aria-required', 'true');
        }
        if (originalSelect['disabled']) {
            input.setAttribute('disabled', originalSelect['disabled']);
            input.setAttribute('aria-disabled', originalSelect['disabled']);
        }

        // set data attributes
        let selectDataAttribute = originalSelect.dataset;
        Object.keys(selectDataAttribute).forEach(key => {
            input.dataset[key] = selectDataAttribute[key];
        });
        //  Create dropdown toggler element and set its attributes
        const toggle = document.createElement('button');
        const toggleClasses = [this._classList.BTN, this._classList.DROPDOWN_TOGGLE];
        toggle.classList.add(...toggleClasses);
        toggle.setAttribute('id', dropdownId);
        toggle.setAttribute('type', 'button');
        toggle.setAttribute('aria-expanded', 'false');
        toggle.setAttribute('aria-haspopup', 'listbox');
        if (originalSelect['aria-describedby']) {
            toggle.setAttribute('aria-labelledby', originalSelect['aria-describedby']);
        }

        if (originalSelect['required']) {
            toggle.setAttribute('aria-required', 'true');
        }

        if (originalSelect['disabled']) {
            toggle.setAttribute('disabled', originalSelect['disabled']);
            toggle.setAttribute('aria-disabled', originalSelect['disabled']);
        }
        toggle.dataset.toggle = 'dropdown';

        const toggleSpan = document.createElement('span');
        let classNames = this.selectedOption.dataset.class;
        if (classNames) toggleSpan.classList.add(...classNames.split(' '));
        const toggleText = document.createTextNode(this.selectedOption.dataset.label || this.selectedOption.text);
        toggleSpan.appendChild(toggleText);

        const toggleIcon = document.createElement('i');
        toggleIcon.setAttribute('aria-hidden', 'true');
        toggleIcon.classList.add(this._classList.CARET);

        toggle.append(toggleSpan);
        toggle.append(toggleIcon);

        const dropdownContent = document.createElement('div');
        dropdownContent.setAttribute('aria-labelledby', dropdownId);
        dropdownContent.setAttribute('role', 'listbox');
        dropdownContent.setAttribute('tabindex', '-1');
        dropdownContent.setAttribute('aria-activedescendant', this.selectedOption.value);
        dropdownContent.classList.add(this._classList.DROPDOWN_MENU);

        [...originalSelect.options].forEach(function (option) {
            const item = document.createElement('a');
            let classNames = option.dataset.class;
            item.setAttribute('href', '#');
            item.setAttribute('role', 'option');
            item.dataset.value = option.dataset.value || option.value;
            item.classList.add(this._classList.DROPDOWN_ITEM);
            if (option.getAttribute('selected')) {
                item.classList.add(this._classList.ACTIVE);
                item.setAttribute('aria-selected', 'true');
                if (item.getAttribute('data-label')) {
                    item.setAttribute('aria-label', `${item.getAttribute('data-label')} selected`);
                }
            }
            if (classNames) item.classList.add(...classNames.split(' '));
            // set data attributes
            let dataAttribute = option.dataset;
            Object.keys(dataAttribute).forEach(key => {
                if (!(key === 'class' && dataAttribute[key] === this._classList.DISPLAY_NONE)) {
                    item.dataset[key] = dataAttribute[key];
                }
            });
            item.dataset.label = option.dataset.label || option.text;

            const itemSpan = document.createElement('span');
            const itemText = document.createTextNode(option.text);
            itemSpan.appendChild(itemText);
            item.appendChild(itemSpan);
            dropdownContent.append(item);
        }, this);

        dropdown.append(input);
        dropdown.append(toggle);
        dropdown.append(dropdownContent);
        this.parentEl.append(dropdown);
        originalSelect.remove();

        const selectIsReady = new CustomEvent('ready.selectable', {detail: {sourceId: originalSelect['id']}});
        document.body.dispatchEvent(selectIsReady);
    }
}

//TODO: split to more simple functions
