(function () {
    let adBlockerTracker = {
        adBlockerPresent: true,
        reason: '',
        dataLayer: null,
        run: function () {
            this.dataLayer = window.dataLayer || [];
            if (document.getElementById('no-ad-blocker-present')) {
                if ($('#ad-blocker-test-image').is(':visible')) {
                    this.adBlockerPresent = false;
                } else {
                    this.reason = '(image block)';
                }
            } else {
                this.reason = '(script block)';
            }
            console.debug('Blocking Ads: ' + (this.adBlockerPresent ? 'Yes ' : 'No ') + this.reason);
            let index = this.dataLayer.findIndex(item => item.site);
            if (index !== -1) {
                this.dataLayer[index].site.ad_blocker = this.adBlockerPresent;
            }
            window.dataLayer = this.dataLayer;
        },
    };
    adBlockerTracker.run();
})();
