'use strict';
import Base from '../../../js/_base';

const EMAIL_REGEX = '^[a-zA-Z0-9._-]+[\\+]?[a-zA-Z0-9._-]+?@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';

export default class EmailFormatValidation extends Base {
    constructor(form) {
        super();
        this.form = form;
        this.isValid = true;
    }

    get selectors() {
        return {
            FORM: 'form',
            FORM_GROUP: '.form-group',
            EMAIL_INPUT: '[type="email"]',
        };
    }

    get classList() {
        return {
            DISPLAY_NONE: 'd-none',
            DISPLAY_FLEX: 'd-flex',
            IS_VALID: 'is-valid',
            IS_INVALID: 'is-invalid',
        };
    }

    get texts() {
        return {
            MESSAGE: 'Email has wrong format.',
        };
    }

    initialize() {
        super.initialize();
    }

    checkEmailFormat() {
        this.isValid = true;
        let emailInputs = this.form.querySelectorAll(this.selectors.EMAIL_INPUT);
        let email = '',
            message = '',
            input = null,
            formGroup = null;
        if (emailInputs.length > 0) {
            emailInputs.forEach(field => {
                email = field.value;
                if (email) {
                    formGroup = field.closest(this._selectors.FORM_GROUP);
                    field.setAttribute('aria-invalid', 'false');
                    input = formGroup?.querySelector('[aria-describedby]');
                    field.classList.remove(this._classList.IS_INVALID);
                    formGroup.classList.remove(this._classList.IS_INVALID);

                    let regexp = new RegExp(EMAIL_REGEX, 'gm');
                    if (!regexp.test(email)) {
                        field.setAttribute('aria-invalid', 'true');
                        field.classList.add(this._classList.IS_INVALID);
                        formGroup.classList.add(this._classList.IS_INVALID);
                        message = this._texts.MESSAGE;
                        field.insertAdjacentHTML('beforebegin', `<div class="message error">${message}</div>`);
                        this.isValid = false;
                        let errorMessage = formGroup?.querySelector('.message.error');
                        errorMessage?.setAttribute('id', input?.getAttribute('aria-describedby'));
                    }
                }
            });
        }
        return this.isValid;
    }
}
