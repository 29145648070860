(function (UX) {
    let insertCaptcha = {
        selectors: {
            CAPTCHA_CONTAINER: '.password-recaptcha-ajax',
            CAPTCHA_EL: '.LBD_CaptchaDiv',
        },
        captchaBox: null,
        init: function () {
            this.captchaBox = document.querySelector(this.selectors.CAPTCHA_CONTAINER);
            if (this.captchaBox) {
                this.control();
            }
        },
        control: function () {
            let el = this.captchaBox.querySelector(this.selectors.CAPTCHA_EL);
            let _this = this;
            if (!el) {
                $.ajax({
                    type: 'GET',
                    dataType: 'html',
                    url: '/pb/widgets/CaptchaResponseHandler/',
                }).done(function (data) {
                    $(_this.captchaBox).append(data);
                });
            }
        },
    };

    UX.insertCaptcha = insertCaptcha;
})(UX);
