'use strict';
import Base from '../../../js/_base';

export default class PasswordEqualityValidation extends Base {
    ERROR_MESSAGE_TEMPLATE = '<div><div class="message error">%message%</div></div>';
    constructor(form) {
        super();
        this.form = form;
        this.passwordInputs = this.form.querySelectorAll(this._selectors.PASSWORD_INPUT);
        this.invalid = {
            passwords: false,
            passwordFormat: false,
        };
        if (this.passwordInputs.length > 1) {
            this.initialize();
        }
    }

    get selectors() {
        return {
            FORM: 'form',
            FORM_GROUP: '.form-group',
            FORM_PASSWORD: '.form-password',
            PASSWORD_INPUT: '.js-equal-validate',
            ERROR_MESSAGE_EL: '.message.error',
        };
    }

    get classList() {
        return {
            DISPLAY_NONE: 'd-none',
            DISPLAY_FLEX: 'd-flex',
            IS_VALID: 'is-valid',
            IS_INVALID: 'is-invalid',
        };
    }

    get texts() {
        return {
            EMAILS_DONT_MATCH: "Passwords don't match.",
        };
    }

    initialize() {
        super.initialize();
        this.control();
    }

    control() {
        if (this.passwordInputs[1]) {
            this.passwordInputs[1].addEventListener('focus', this.onChangePassword.bind(this));
            this.passwordInputs[1].addEventListener('input', this.onChangePassword.bind(this));
        }
    }
    onChangePassword(e) {
        this.checkPasswordEquality(e.target);
    }
    checkPasswordEquality() {
        const passwordInputsArray = Array.from(this.passwordInputs);
        const isFilled = passwordInputsArray.reduce((result, item) => !!item.value && result, true);
        const isEqual = !!passwordInputsArray.reduce((result, item) => (item.value === result.value ? result : false));
        this.invalid.passwords = false;
        if (passwordInputsArray.length > 1) {
            if (isFilled) {
                const formGroup = this.passwordInputs[1].closest(this._selectors.FORM_GROUP),
                    formPassword = formGroup.querySelector(this._selectors.FORM_PASSWORD);
                let field = this.passwordInputs[1],
                    input = null;

                field.setAttribute('aria-invalid', 'false');
                field.classList.remove(this._classList.IS_INVALID);
                formGroup.classList.remove(this._classList.IS_INVALID);
                formGroup.querySelector(this._selectors.ERROR_MESSAGE_EL)?.remove();
                input = formGroup?.querySelector('[aria-describedby]');
                this.invalid.passwords = false;

                if (!isEqual) {
                    formGroup.classList.add(this._classList.IS_INVALID);

                    this.passwordInputs[1].classList.add(this._classList.IS_INVALID);
                    this.passwordInputs[1].setAttribute('aria-invalid', 'true');

                    formPassword?.insertAdjacentHTML(
                        'beforebegin',
                        `<div><div class="message error">${this._texts.EMAILS_DONT_MATCH}</div></div>`
                    );
                    this.invalid.passwords = true;
                    let errorMessage = formGroup?.querySelector('.message.error');
                    errorMessage?.setAttribute('id', input?.getAttribute('aria-describedby'));
                }
            }
        }
        return !this.invalid.passwords;
    }
}
