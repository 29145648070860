import 'ui-core/basic/inputs/js/inputs';

(function (UX) {
    let fieldsCtrl = UX.fieldsCtrl || {};

    fieldsCtrl.accessibility.buttons.init = function () {
        fieldsCtrl.accessibility.buttons.prepare();
        $(document).on('keydown', 'a[role=button]:not([disabled])', function (event) {
            if (event.which === 32 || event.which === 13) {
                event.preventDefault();
                $(this).click();
            }
        });
    };

    UX.fieldsCtrl = fieldsCtrl; // add to global namespace
})(UX);
