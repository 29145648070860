'use strict';
import Base from '../../js/_base';

export default class ChinaRegionUtils extends Base {
    constructor() {
        super();
        this.initialize();
    }

    get selectors() {
        return {
            GET_ACCESS_CHINA_LABEL: '[name="getAccessChinaLabel"]',
            GET_ACCESS_BUTTON: '.js--get-access-button span',
        };
    }

    get classList() {
        return {};
    }

    initialize(originalSelect) {
        super.initialize();
        this.getAccessChinaLabel = document.querySelector(this._selectors.GET_ACCESS_CHINA_LABEL);
        if (this.getAccessChinaLabel) {
            this.setChineseLabel();
        }
    }
    setChineseLabel() {
        const label = this.getAccessChinaLabel.value;
        let cnItems = this.getAccessButtons();
        if (cnItems.length > 0) {
            cnItems.forEach(cnItem => {
                cnItem.innerText = label;
            });
        }
    }
    getAccessButtons() {
        return document.querySelectorAll(this._selectors.GET_ACCESS_BUTTON);
    }
}
