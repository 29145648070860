'use strict';
import Base from '../../../js/_base';
import EmailFormatValidation from './_email-format-validation';
import PhoneFormatValidation from './_phone-format-validation';
import ExcludedCharacters from './_excluded-characters';
import {escapeHtml} from '../../../basic/utils/js/utils';

export default class ProfileUpdate extends Base {
    get selectors() {
        return {
            FORM: '[name="userProfileForm"]',
            EMAIL_INPUT: '[type="email"]',
            REQUIRED_FIELD: '[required]',
            SUBMIT_BTN: '.btn-submit',
            FORM_GROUP: '.form-group',
            FORM_CONTROL: '.form-control',
            FORM_ROW: '.form-row',
            MESSAGE: '.message',
            LABEL: 'label',
        };
    }

    get classList() {
        return {
            DISPLAY_NONE: 'd-none',
            DISPLAY_FLEX: 'd-flex',
            IS_VALID: 'is-valid',
            IS_INVALID: 'is-invalid',
        };
    }

    get texts() {
        return {
            FILL_FIELD: 'This required field was missing. Please enter your %form_label%.',
            ONLY_ALPHANUMERIC: 'Please use only alpha-numeric characters.',
            SELECT_OPTION: 'This required field was missing. Please select your %form_label% from the drop-down.',
        };
    }

    constructor() {
        super();
        this.form = document.querySelector(this._selectors.FORM);
        if (this.form) {
            this.form.setAttribute('novalidate', 'true');
            this.submitBtn = this.form.querySelector(this._selectors.SUBMIT_BTN);
            this.initialize();
        }
    }

    initialize() {
        super.initialize();
        this.emailFormatValidation = new EmailFormatValidation(this.form);
        this.phoneFormatValidation = new PhoneFormatValidation(this.form);
        this.excludedCharacters = new ExcludedCharacters(this.form);
        this.control();
    }

    control() {
        if (this.form) {
            this.form.addEventListener('submit', this.onSubmit.bind(this));
        }
    }

    onSubmit(event) {
        let isFilled = this.validateRequired();
        let isMail = this.emailFormatValidation.checkEmailFormat();
        let isPhone = this.phoneFormatValidation.checkPhoneFormat();
        let isAllowed = this.excludedCharacters.checkExcludedCharacters();

        if (isFilled && isMail && isPhone && isAllowed) {
            this.addData('submit', 'submit');
            return true;
        } else {
            event.preventDefault();
            this.focusFirstInvalid();
            this.form.scrollIntoView();
        }
        return false;
    }
    //TODO: all copies of focusFirstInvalid methods should be moved into one module with helpers
    focusFirstInvalid() {
        let formGroup = this.form.querySelector('.form-group.is-invalid');
        let elToFocus = formGroup.querySelector('select') || formGroup.querySelector(this._selectors.FORM_CONTROL);
        let elMessage = formGroup.querySelector('.message');
        elMessage?.setAttribute('aria-live', 'assertive');
        elToFocus?.focus();
    }

    validateRequired() {
        let isValid = true;
        let requiredFields = this.form.querySelectorAll(this._selectors.REQUIRED_FIELD);
        requiredFields.forEach((field, index) => {
            this.setValid(field);
            if (!field.value || field.value === '' || (field.type === 'checkbox' && field.checked === false)) {
                this.setInvalid(field);
                isValid = false;
            }
        }, this);
        return isValid;
    }
    setValid(field) {
        let formGroup = field.closest(this._selectors.FORM_GROUP);
        field.setAttribute('aria-invalid', 'false');
        field.classList.remove(this._classList.IS_INVALID);
        formGroup?.classList.remove(this._classList.IS_INVALID);
        formGroup?.querySelector(this._selectors.MESSAGE)?.remove();
        field?.classList.remove(this._classList.IS_INVALID);
    }
    setInvalid(field) {
        field.setAttribute('aria-invalid', 'true');
        field.classList.add(this._classList.IS_INVALID);
        let dropdown = null,
            formGroup = null,
            formRow = null,
            label = null,
            input = null,
            message = '';
        formGroup = field.closest(this._selectors.FORM_GROUP);
        label = formGroup?.querySelector(this._selectors.LABEL).innerText.replace(':', '');
        if (field.tagName === 'INPUT') {
            input = formGroup?.querySelector('input[aria-describedby]');
        } else if (field.tagName === 'SELECT') {
            input = formGroup?.querySelector('select[aria-describedby]');
        }
        formGroup?.classList.add(this._classList.IS_INVALID);
        switch (field.name) {
            case 'sageCountryInstitution.countryCode':
            case 'sageprofession.profession1':
            case 'sageprofession.profession2':
                message = this._texts.SELECT_OPTION.replace('%form_label%', escapeHtml(label));
                dropdown = field;
                dropdown.classList.add(this._classList.IS_INVALID);
                dropdown?.insertAdjacentHTML('beforebegin', `<div class="message error">${message}</div>`);
                break;
            case 'terms.value':
                message = this._texts.CONFIRM_TERMS;
                formRow = field.closest(this._selectors.FORM_ROW);
                formRow?.insertAdjacentHTML('beforebegin', `<div class="message error">${message}</div>`);
                break;
            default:
                message = this._texts.FILL_FIELD.replace('%form_label%', escapeHtml(label));
                field.insertAdjacentHTML('beforebegin', `<div class="message error">${message}</div>`);
        }
        let errorMessage = formGroup?.querySelector('.message.error');
        errorMessage?.setAttribute('id', input?.getAttribute('aria-describedby'));
    }

    //TODO: make it shareable - move it to utilities?
    addData(name, value = '') {
        let el = this.form.querySelector('[name="' + name + '"]');
        if (el) {
            value = el.getAttribute('value');
            el.removeAttribute('name');
        }
        let input = document.createElement('input');
        input.setAttribute('name', name);
        input.setAttribute('value', value);
        input.setAttribute('type', 'hidden');
        this.form.appendChild(input);
    }
}
