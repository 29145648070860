import {whenAvailable} from '../../../../../js/_utils';

(function (UX) {
    let purchaseOptions = {
        widget: null,
        radios: null,
        selectors: {
            WIDGET_CONTAINER: '.eCommercePurchaseAccessWidget',
            RADIO: '[type="radio"]',
            ADD_TO_CART_BUTTON: '[data-bind="addItem"]',
            ADDED_MESSAGE: '.addedMessage',
        },
        init: function () {
            this.widget = document.querySelector(this.selectors.WIDGET_CONTAINER);
            this.radios = this.widget?.querySelectorAll(this.selectors.RADIO);
            if (this.widget && this.radios.length > 0) {
                this.control();
            }
        },
        control: function () {
            this.radios.forEach(radio => {
                radio.addEventListener('change', purchaseOptions.onChangeHandler);
                if (radio.checked) {
                    const fn = purchaseOptions.onChangeHandler.bind(radio);
                    fn();
                }
            });
        },
        addToCart: function () {
            purchaseOptions.radios.forEach(radio => {
                radio.setAttribute('disabled', true);
            });
        },
        onChangeHandler: function (e) {
            const dataKey = this.dataset.key;
            let addToCartButton = purchaseOptions.widget.querySelector(purchaseOptions.selectors.ADD_TO_CART_BUTTON);
            addToCartButton.dataset.key = dataKey;
            addToCartButton.dataset.entity = dataKey;
            addToCartButton.classList.remove('disabled');
            addToCartButton.setAttribute('aria-disabled', 'false');
            addToCartButton.addEventListener('click', purchaseOptions.addToCart);
            let addedMessage = purchaseOptions.widget.querySelector(purchaseOptions.selectors.ADDED_MESSAGE);
            addedMessage.dataset.item = dataKey;
        },
    };
    UX.purchaseOptions = purchaseOptions;

    $(document).ready(function () {
        UX.purchaseOptions.init();
        whenAvailable('commerce', function () {
            commerce.cart.buyingList.refresh = function (cartInfo) {
                //console.debug('commerce.cart.buyingList.refresh...');
                commerce.cart.notify(commerce.cart.buyingList, cartInfo);
            };
        });
    });
})(UX);
