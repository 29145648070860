import {whenAvailable} from '../../../../../js/_utils';

(function (UX) {
    $(document).ready(function () {
        whenAvailable('commerce', function () {
            commerce.CartIndicatorWidget.prototype.update = function (model) {
                const $cartSize = this.find('[data-id="cart-size"]');
                const $cartIndicator = this.find('#cartLabel');
                const $cartIcon = $cartIndicator.find('[class^="icon-"], [class*="icon-"]');
                let altText = '';

                if (model.size === 0) {
                    $cartSize.hide();
                    $cartSize.html(model.size);
                    altText = 'Empty cart';
                    $cartIndicator.attr('alt', altText);
                    $cartIndicator.attr('aria-label', $cartIndicator.attr('aria-label') + ': ' + altText);
                    $cartIcon.attr('class', 'icon-shopping_cart');
                } else {
                    $cartSize.show('hidden');
                    $cartSize.removeClass('hidden');

                    $cartSize.html(model.size);
                    altText = model.size + ' item' + (model.size > 1 ? 's' : '') + ' in cart';
                    $cartIndicator.attr('alt', altText);
                    $cartIndicator.attr('aria-label', 'Shopping Cart: ' + altText);
                    $cartIcon.attr('class', 'icon-cart-filled');
                }
            };
        });

        window.addEventListener('load', _e => {
            let cartIndicator = document.querySelector('#cartLabel');
            cartIndicator?.setAttribute('aria-label', 'Shopping Cart: ' + cartIndicator.getAttribute('alt'));
        });
    });
})(UX);
