import '../../../node_modules/bootstrap/js/src/util';
import '../../../node_modules/bootstrap/js/src/collapse';
import '../../../node_modules/bootstrap/js/src/dropdown';
import '../../../node_modules/bootstrap/js/src/tooltip';
import '../../../node_modules/bootstrap/js/src/popover';
jQuery.fn.bs4tooltip = jQuery.fn.tooltip; // this prevents conflicts between Bootstrap and jQueryUI tooltips
$(function () {
    if (!document.querySelector('.ecomm-vat')) {
        $('[data-toggle="tooltip"]').bs4tooltip();
    } else {
        $('[data-toggle="tooltip"]').bs4tooltip({trigger: 'manual'});

        $('body').on('mouseleave', '.tooltip', function () {
            $('[data-toggle="tooltip"]').bs4tooltip('hide');
        });

        $('[data-toggle="tooltip"] > i').on('mouseenter', function () {
            $('[data-toggle="tooltip"]').bs4tooltip('show');
        });

        $('[data-toggle="tooltip"]').on('click', function () {
            $(this).bs4tooltip('show');
        });

        $('[data-toggle="tooltip"]').on('focus', function () {
            $(this).bs4tooltip('show');
        });

        $(document).on('keydown', function (event) {
            if (event.key === 'Escape') {
                $('[data-toggle="tooltip"]').bs4tooltip('hide');
            }
        });
    }

    $(document).ajaxComplete(function (event, jqxhr, settings) {
        if (document.querySelector('.ecomm-vat')) {
            $('[data-toggle="tooltip"]').bs4tooltip({trigger: 'manual'});

            $('body').on('mouseleave', '.tooltip', function () {
                $('[data-toggle="tooltip"]').bs4tooltip('hide');
            });

            $('[data-toggle="tooltip"] > i').on('mouseenter', function () {
                $('[data-toggle="tooltip"]').bs4tooltip('show');
            });

            $('[data-toggle="tooltip"]').on('click', function () {
                $(this).bs4tooltip('show');
            });

            $('[data-toggle="tooltip"]').on('focus', function () {
                $(this).bs4tooltip('show');
            });

            $(document).on('keydown', function (event) {
                if (event.key === 'Escape') {
                    $('[data-toggle="tooltip"]').bs4tooltip('hide');
                }
            });
        }
    });
});
