(function (UX) {
    let payment = {
        widget: null,
        submitBtn: null,
        form: null,
        checkBox: null,
        selectors: {
            WIDGET_CONTAINER: '.eCommerceCheckoutPaymentWidget',
            FORM: 'form',
            SUBMIT: '[type="submit"]',
            CHECK_BOX: '#acceptTermsConditions',
        },
        init: function () {
            this.widget = document.querySelector(this.selectors.WIDGET_CONTAINER);
            if (this.widget) {
                this.form = this.widget.querySelector(this.selectors.FORM);
                if (this.form) {
                    this.submitBtn = this.form.querySelector(this.selectors.SUBMIT);
                    this.checkBox = document.querySelector(this.selectors.CHECK_BOX);
                    this.control();
                    this.checkTerms(this.checkBox, this.submitBtn);
                }
            }
        },
        control: function () {
            this.form.addEventListener('submit', payment.onSubmitHandler);
        },
        onSubmitHandler: function (e) {
            payment.submitBtn.setAttribute('disabled', true);
            if (literatum && typeof literatum.FullPageLoading === 'function') {
                const loading = new literatum.FullPageLoading();
                loading.start();
            }
            return true;
        },
        checkTerms: function (checkBox, submitBtn) {
            const validate = () =>
                !checkBox.checked ? submitBtn.setAttribute('disabled', true) : submitBtn.removeAttribute('disabled');

            validate();
            checkBox.addEventListener('click', () => validate());
        },
    };
    UX.payment = payment;
    $(document).ready(function () {
        UX.payment.init();
    });
})(UX);
