import 'ui-theme/components/tabs/js/tabs';
import Tab from 'ui-theme/node_modules/ui-core/plugins/tabs/js/tabs';
import Util from 'ui-theme/node_modules/ui-core/utility/js/bootstrap-utils';
import '../../drop-block/js/drop-block';
import {keyCodes} from '../../../js/_utils';

let isMobile = true;
const FIRST_INDEX = 0;
const DATA_KEY = 'bs.tab';
const EVENT_KEY = `.${DATA_KEY}`;
const DATA_API_KEY = '.data-api';
const Key = keyCodes;
const ClassName = {
    DROPDOWN_MENU: 'dropdown-menu',
    ACTIVE: 'active',
    DISABLED: 'disabled',
    FADE: 'fade',
    SHOW: 'show',
};

const Selector = {
    DROPDOWN: '.dropdown',
    NAV_LIST_GROUP: '.tab__nav',
    ACTIVE: '.active',
    ACTIVE_UL: '.active',
    OLD_DATA_TOGGLE:
        '.tab__nav > li:not(.arrows-slide), [data-toggle="tab"], [data-toggle="pill"], [data-toggle="list"]',
    DATA_TOGGLE: '[data-toggle="tab"], [data-toggle="pill"], [data-toggle="list"]',
    DROPDOWN_TOGGLE: '.dropdown-toggle',
    DROPDOWN_ACTIVE_CHILD: '> .dropdown-menu .active',
    TAB: '.tab',
    TAB_CONTENT: '.tab__content',
};

const Event = {
    HIDE: `hide${EVENT_KEY}`,
    HIDDEN: `hidden${EVENT_KEY}`,
    SHOW: `show${EVENT_KEY}`,
    SHOWN: `shown${EVENT_KEY}`,
    CLICK_DATA_API: `click${EVENT_KEY}${DATA_API_KEY}`,
};
$(document).off(Event.CLICK_DATA_API, Selector.OLD_DATA_TOGGLE);
$(document).on(Event.CLICK_DATA_API, Selector.DATA_TOGGLE, function (event) {
    var refElement = $(this).attr('data-toggle');
    if (refElement === 'tab') {
        event.preventDefault();
    }
    Tab._jQueryInterface.call($(this), 'show');
    if (document.querySelector('.issue-item__authors')) {
        UX.issueItem.truncate();
    }
});
Tab.prototype._activate = function (element, container, callback) {
    const activeElements =
        container && (container.nodeName === 'UL' || container.nodeName === 'OL')
            ? $(container).find(Selector.ACTIVE_UL)
            : $(container).children(Selector.ACTIVE);

    const [active, activeAnchor] = activeElements.toArray();
    const isTransitioning = callback && active && $(active).hasClass(ClassName.FADE);
    const complete = () => this._transitionComplete(element, active, callback, activeAnchor);

    if (active && isTransitioning) {
        const transitionDuration = Util.getTransitionDurationFromElement(active);

        $(active)
            .removeClass(ClassName.SHOW)
            .one(Util.TRANSITION_END, complete)
            .emulateTransitionEnd(transitionDuration);
    } else {
        complete();
    }
};

Tab.prototype._transitionComplete = function (element, active, callback, activeAnchor) {
    if (activeAnchor) {
        activeAnchor.classList.remove(ClassName.ACTIVE);
    }
    if (active) {
        active.classList.remove(ClassName.ACTIVE);
        if (active?.getAttribute('role') === 'tab') {
            active.setAttribute('aria-selected', false);
        }
        const dropdownChild = $(active.parentNode).find(Selector.DROPDOWN_ACTIVE_CHILD)[0];
        if (dropdownChild) {
            $(dropdownChild).removeClass(ClassName.ACTIVE);
        }
    }

    element.classList.add(ClassName.ACTIVE);
    if (element.getAttribute('role') === 'tab') {
        element.setAttribute('aria-selected', true);
        element.setAttribute('tabindex', 0);
    }

    Util.reflow(element);

    if (element.classList.contains(ClassName.FADE)) {
        element.classList.add(ClassName.SHOW);
    }

    if (element.parentNode && $(element.parentNode).hasClass(ClassName.DROPDOWN_MENU)) {
        const dropdownElement = $(element).closest(Selector.DROPDOWN)[0];

        if (dropdownElement) {
            const dropdownToggleList = [].slice.call(dropdownElement.querySelectorAll(Selector.DROPDOWN_TOGGLE));
            $(dropdownToggleList).addClass(ClassName.ACTIVE);
        }
        element.setAttribute('aria-expanded', true);
    }

    if (callback) {
        callback();
    }
};

(function (UX) {
    let $body = $('body');
    let tab = UX.tab || {};
    tab.classes = tab.classes || {};
    tab.classes.HIDE = 'hide';
    tab.selectors = tab.selectors || {};
    tab.selectors.ACTIVE = '.active';
    tab.selectors.DROPBLOCK = '.dropBlock';
    tab.selectors.ROLE_TABLIST = '[role="tablist"]';
    tab.selectors.ROLE_TAB = '[role="tab"]';
    tab.swipeDisableClass = 'arrows-slide__single--disabled';

    (tab.control = function () {
        $body.on('click', '.tab-link', function (e) {
            e.preventDefault();
            const $this = $(this),
                documentScrollTop = $(document).scrollTop();

            $this.attr('data-scroll-back-to', documentScrollTop);
            tab.triggerExternal($this);
        });
        $body.on('click', '[data-mobile-toggle="dropdown"] a, .dropblock--tab a', function (e) {
            tab.on.select.dropdown($(this));

            if ($('.js--open[data-db-target-for]').length) UX.dropBlock.on.hide();
        });
        $body.on('click', '.tab__nav a, .tab__nav button', function () {
            const _this = $(this);
            const tabId = tab.getPaneTarget(_this);
            $(document).trigger('tabs:changeActiveTab', {tabId: tabId});
            const $parent = _this.parents('.tab');
            if ($parent.hasClass('tab--lazy-load')) {
                if ($(tabId).attr('data-ajaxloaded') !== 'true') {
                    tab.getContent($(tabId).find('.tab-lazy'));
                }
            }
        });
        $body.on('click touchend', '.arrows-slide .next', function (e) {
            e.preventDefault();
            let $activeNav = $(this).closest('.tab__nav').find('.active');
            let $activePane = $(this).closest('.tab').find('.tab__content .tab__pane.active');
            if ($activeNav.next().not('.arrows-slide').length > 0) {
                const $newActiveEl = $activeNav.removeClass('active').next().not('.arrows-slide');
                $newActiveEl.addClass('active').show();
                $activePane.removeClass('active').next().addClass('active');
                tab.navToExternal($newActiveEl);
                if (!$activeNav.next().not('.arrows-slide').next().not('.arrows-slide').length) {
                    $(this).addClass(tab.swipeDisableClass);
                }
                $(this).closest('.arrows-slide').find('.prev').removeClass(tab.swipeDisableClass);
            }
        });

        $body.on('click touchend', '.arrows-slide .prev', function (e) {
            e.preventDefault();
            let $activeNav = $(this).closest('.tab__nav').find('.active');
            let $activePane = $(this).closest('.tab').find('.tab__content .tab__pane.active');
            if ($activeNav.prev().not('.arrows-slide').length > 0) {
                const $newActiveEl = $activeNav.removeClass('active').prev().not('.arrows-slide');
                $newActiveEl.addClass('active').show();
                $activePane.removeClass('active').prev().addClass('active');
                tab.navToExternal($newActiveEl);
                if (!$activeNav.prev().not('.arrows-slide').prev().not('.arrows-slide').length) {
                    $(this).addClass(tab.swipeDisableClass);
                }
                $(this).closest('.arrows-slide').find('.next').removeClass(tab.swipeDisableClass);
            }
        });
        // Get postback active tab
        tab.setPostbackActiveTab(tab.getPostbackActiveTab());
        tab.horizontalScrolling = () => {
            $(document).ready(function () {
                let isDown = false;
                let startX;
                let scrollLeft;

                $('.scroll').mousedown(function (e) {
                    isDown = true;
                    $(this).addClass('active');
                    startX = e.pageX - $(this).offset().left;
                    scrollLeft = $(this).scrollLeft();
                });

                $('.scroll').mouseleave(function () {
                    isDown = false;
                    $(this).removeClass('active');
                });

                $('.scroll').mouseup(function () {
                    isDown = false;
                    $(this).removeClass('active');
                });

                $('.scroll').mousemove(function (e) {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - $(this).offset().left;
                    const walk = (x - startX) * 2; //scroll-fast
                    $(this).scrollLeft(scrollLeft - walk);
                });
            });
        };
        tab.additionalControl = function () {
            document.querySelectorAll(Selector.DATA_TOGGLE).forEach(function (el) {
                tab.check.empty($(el));
            });
            const tabLists = document.querySelectorAll(tab.selectors.ROLE_TABLIST);
            tabLists.forEach(function (tabList) {
                tabList.tabs = tabList.querySelectorAll(tab.selectors.ROLE_TAB);
                tabList.tabFocus = 0;
                tabList.addEventListener('keydown', tab.on.keyDownHandler);
                tabList.addEventListener('click', tab.on.mouseClick);
                tab.checkActive(tabList);
                tab.tabActiveFocus();
            });
            const tabs = $('nav .tab__nav.rlist');

            tab.on.swipeBuild(tabs);
            $(document).trigger('init.bs.tab');
            tab.horizontalScrolling();

            $(document).on('screen-sm-off', function () {
                tab.on.swipeBuild(tabs);
                tab.horizontalScrolling();
            });
        };
        tab.additionalControl();
    }),
        (tab.triggerExternal = function ($el) {
            tab.on.select.external($el);
        });

    tab.navToExternal = function ($activeNav) {
        const $externalLink = $activeNav.find('.external');
        if ($externalLink.length) {
            window.location.href = $externalLink.attr('href');
        }
    };
    (tab.getPaneTarget = function ($elem) {
        return $elem.data('target') || $elem.attr('href');
    }),
        (tab.getPostbackActiveTab = function () {
            let param = 'tabActivePane';
            param = param.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]'); /*eslint-disable-line*/
            const regex = new RegExp('[\\?&]' + param + '=([^&#]*)');
            const results = regex.exec(location.search);
            return results == null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        }),
        (tab.setPostbackActiveTab = function (tabID) {
            if (tabID !== '') {
                const $activeTabs =
                    $("a[data-simple-tab-id='" + tabID + "']").length > 0
                        ? $("a[data-simple-tab-id='" + tabID + "']")
                        : $("button[data-simple-tab-id='" + tabID + "']");
                if ($activeTabs.length > 0) {
                    $activeTabs.each(function () {
                        $activeTabs.trigger('click');
                    });
                }
            }
        });

    tab.setTabFocusIndexOnLoad = function (activeEle, tabList) {
        const linkActive = activeEle.querySelector(this.selectors.ACTIVE);
        const index = this.getNodeListElementIndex(tabList.tabs, linkActive);

        if (index < 0) return;
        tabList.tabFocus = index;
    };

    tab.checkActive = function (tabList) {
        const activeEl = tabList.querySelector(tab.selectors.ACTIVE);
        if (activeEl) return this.setTabFocusIndexOnLoad(activeEl, tabList);
        return this.setFirstActive(tabList);
    };

    tab.tabActiveFocus = function () {
        const nav = document.querySelector('.tab nav'),
            tabsAdvancedSearch = Array.from(document.querySelectorAll('.tab--generic .tab__nav .tab__nav__item__link')),
            tabsSearchResults = Array.from(
                document.querySelectorAll('.search-result__advanced-search-wrapper .tab__nav .tab__nav__item__link')
            );
        const handleTabFocus = function (event) {
            let activeBtn = nav?.querySelector('.tab__nav__item__link.active');

            if (activeBtn) {
                activeBtn?.focus();
            }
        };

        nav?.addEventListener('focusin', handleTabFocus);

        const removeListenerInsideNav = function (event) {
            if (event.target.closest('nav')) {
                nav?.removeEventListener('focusin', handleTabFocus);
            }
        };

        document.addEventListener('focusin', removeListenerInsideNav);

        const addListenerOutsideNav = function (event) {
            if (!event.target.closest('nav')) {
                nav?.addEventListener('focusin', handleTabFocus);
            }
        };

        document.addEventListener('focusin', addListenerOutsideNav);

        const handleArrowUp = function (event) {
            const tabContent = event.target.closest('div.tab__pane').getAttribute('id');
            const tabButton = document.querySelector(`button[data-target="#${tabContent}"]`);

            if (event.key === 'ArrowUp') {
                event.preventDefault();
                tabButton.focus();
            }
        };
        const handleArrowDown = function (event) {
            const tabContent = document.querySelector(event.target.getAttribute('data-target'));
            const ele = tabContent.querySelectorAll(
                'input, button, select, textarea, a[href]:not([href="#"]), [tabindex]:not([tabindex="-1"])'
            );

            ele[0].addEventListener('keydown', handleArrowUp);

            if (event.key === 'ArrowDown') {
                event.preventDefault();
                ele[0].setAttribute('role', 'option');
                ele[0].focus();
            }
        };

        if (tabsAdvancedSearch.length > 0) {
            tabsAdvancedSearch.forEach(tab => {
                tab.addEventListener('keydown', handleArrowDown);
            });
        } else if (tabsSearchResults.length > 0) {
            tabsSearchResults.forEach(tab => {
                tab.addEventListener('keydown', handleArrowDown);
            });
        }
    };

    tab.setFirstActive = function (tabList) {
        const isActive = tabList.querySelector(tab.selectors.ACTIVE);
        window.innerWidth <= 767 ? (isMobile = true) : (isMobile = false);
        let collateral = document.querySelector('.pill--open');
        if (isMobile && collateral) {
            return;
        } else if (!isActive) {
            let firstItem = tabList.querySelector(`${tab.selectors.ROLE_TAB}:first-of-type`);
            firstItem?.click();
            return firstItem;
        }
    };

    tab.on.mouseClick = function (e) {
        let quickSearch = document.querySelector('.quick-search__searchbox');
        if (!quickSearch.contains(this.tabs[this.tabFocus])) {
            this.tabs[this.tabFocus]?.setAttribute('tabindex', -1);
            if (e.target && e.target.matches('.tab__nav__item__link')) {
                this.tabFocus = tab.getNodeListElementIndex(this.tabs, e.target);
            }
        }
    };

    tab.on.keyDownHandler = function (e) {
        if ([Key.LEFT, Key.RIGHT].includes(e.keyCode)) {
            this.tabs[this.tabFocus].setAttribute('tabindex', -1);
            if ([Key.RIGHT].includes(e.keyCode)) {
                this.tabFocus++;
                // If we're at the end, go to the start
                if (this.tabFocus >= this.tabs.length) {
                    this.tabFocus = FIRST_INDEX;
                }
            } else if ([Key.LEFT].includes(e.keyCode)) {
                this.tabFocus--;
                // If we're at the start, move to the end
                if (this.tabFocus < FIRST_INDEX) {
                    this.tabFocus = this.tabs.length - 1;
                }
            }

            this.tabs[this.tabFocus].setAttribute('tabindex', 0);
            this.tabs[this.tabFocus].focus();
        }
        if (e.keyCode === Key.SPACE) {
            $(this.tabs[this.tabFocus]).click();
        }
    };

    tab.on.dropdownBuild = function ($ele, index) {
        if (UX.dropBlock) {
            UX.dropBlock.init();
        }

        $ele.wrap('<div class="dropBlock no-auto-width" data-db-parent-of="dbTab-' + index + '"></div>');

        const $dropdownContainer = $ele.closest(tab.selectors.DROPBLOCK);
        const $activeTab = $ele.find(tab.selectors.ACTIVE);

        if ($ele.find('>li').length === 1) {
            $dropdownContainer.prepend(
                `<a class='dropBlock__link no-pointer-events' ><span>${$activeTab.text()}</span></a>`
            );
            $ele.hide();
        } else {
            $dropdownContainer.prepend(
                `<a href='#'class='dropBlock__link' data-db-target-for='dbTab-${index}'><span>${$activeTab.text()}</span><i class='icon-arrow_down' aria-hidden='true'></i></a>`
            );
            $ele.attr('data-db-target-of', 'dbTab-' + index);
        }
    };

    tab.on.swipeBuild = function (elem, tabNavWidth) {
        if (!elem.parent().hasClass('scroll')) {
            elem.wrap('<div class="swipe__wrapper"><div class="scroll"></div></div>');
            elem.closest('.tab--generic').addClass('tab--res');
            if (!tabNavWidth) {
                let tabContainer = elem[0]?.closest(Selector.TAB);
                let clone = tabContainer?.cloneNode(true);
                if (clone) {
                    clone?.querySelector(Selector.TAB_CONTENT)?.remove();
                    clone.visibility = 'hidden';
                    clone.position = 'absolute';
                    clone.left = '-9999px';
                    document.body.appendChild(clone);
                    clone.remove();
                }
            }

            elem.width('max-content');
        }
    };

    tab.check.empty = function ($tabItem) {
        if ($tabItem.is('a:not([href^="#"])') || $tabItem.hasClass('external')) {
            return;
        }

        if ($tabItem[0].tagName === 'BUTTON' && $tabItem[0].querySelector('h3') !== null) {
            let tabContent = document.querySelector(`nav + ${Selector.TAB_CONTENT}`),
                tabItemContent = tabContent?.querySelector($tabItem[0].getAttribute('data-target')),
                isVisible = tabItemContent?.innerText,
                list = $tabItem[0].closest('ul');

            if (
                (!tabItemContent.querySelector('.tab-lazy') && isVisible === '') ||
                tabItemContent?.querySelector('.search-result__no-result')
            ) {
                $tabItem[0].closest('li').remove();
            }

            if (!list.querySelectorAll('li').length) {
                let wrapperDiv = list?.closest('div'),
                    noResults = document.createElement('h4');

                list.closest('nav').remove();
                noResults.textContent = 'No data are found.';
                wrapperDiv.appendChild(noResults);
            }
        }

        const pane = tab.getPaneTarget($tabItem);
        const $pane = $(pane);
        const hasNoContent = !$pane.text().trim().length || $pane.find('.emptyTab').length || $pane.is(':empty');
        if (!$pane.length) {
            $tabItem.parent().addClass(tab.classes.HIDE);
        }

        if ($pane.length && hasNoContent && !tab.isPbEditMode() && !$pane.find('.tab-lazy').length) {
            $pane.remove();
            $tabItem.parent().remove();
            return true;
        } else {
            $pane.removeClass(tab.classes.HIDE);
            $tabItem.parent().removeClass(tab.classes.HIDE);
            return false;
        }
    };

    tab.isPbEditMode = function () {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get('pbEdit');
    };

    tab.getNodeListElementIndex = function (nodeList, ele) {
        return Array.from(nodeList).indexOf(ele);
    };

    UX.tab = tab;
})(UX);
