(function ($, UX) {
    let journalCoverBox = {
        selectors: {
            VIEW_FULL: '.journal-cover-box__description__link',
            TEXT_CONTAINER: '.journal-cover-box__description__text',
            TEXT_ELEMENT: '.journal-cover-box__description__text span',
        },
        truncateOptions: {
            lines: 5,
            seeMoreLink: false,
            ellipsisChar: '&hellip; | ',
        },
        textContainer: null,
        textElement: null,
        viewFull: null,
        init: function () {
            this.textContainer = document.querySelector(this.selectors.TEXT_CONTAINER);
            if (this.textContainer) {
                this.textElement = document.querySelector(this.selectors.TEXT_ELEMENT);
                this.viewFull = document.querySelector(this.selectors.VIEW_FULL);
                if (this.textElement) {
                    this.control();
                }
            }
        },
        control: function () {
            if (this.viewFull) {
                this.moveBefore();
            }
            $(this.textContainer).truncate(this.truncateOptions);
            if (this.viewFull) {
                this.moveAfter();
            }
        },
        moveBefore: function () {
            this.textElement.before(this.viewFull);
            this.viewFull.style.display = 'inline-block';
            this.viewFull.style.marginRight = '5em';
        },
        moveAfter: function () {
            this.viewFull.style.marginRight = '0';
            this.viewFull.style.display = 'inline';
            this.textElement.after(this.viewFull);
        },
    };
    UX.journalCoverBox = journalCoverBox;
})($, UX);
