//Keydown numbers
export const keyCodes = {
    TAB: 9,
    RETURN: 13,
    ENTER: 13,
    ESC: 27,
    SPACE: 32,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
};

//Outline focus styling state
const outlineStylingActive = true;

export const isOutlineStylingActive = () => outlineStylingActive;

export const mimicLoading = async (timeout = 2000) => {
    return await new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, timeout);
    });
};

export const validateEmail = value => {
    return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        value
    );
};

export const whenAvailable = (what, callback) => {
    let interval = 10; // ms
    window.setTimeout(function () {
        if (window[what]) {
            callback(window[what]);
        } else {
            whenAvailable(what, callback);
        }
    }, interval);
};

export const toast = function (status, msg) {
    const classes = {
        _ALERT: ['alert', 'alert--floating'],
        _ALERT_SUCCESFUL: 'alert--success',
    };
    const _ALERT_HIDE_INTERVAL = 4000;
    let alert = document.createElement('div');
    alert.setAttribute('role', 'alert');
    alert.setAttribute('role', 'alert');
    classes._ALERT.forEach(c => {
        alert.classList.add(c);
    });
    alert.append(msg);
    if (status) {
        alert.classList.add(classes._ALERT_SUCCESFUL);
    }
    document.querySelector('body').appendChild(alert);
    window.setTimeout(() => alert.remove(), _ALERT_HIDE_INTERVAL);
};

export const escapeHtml = function (unsafe) {
    return unsafe
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
};

export const isIE = () => navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;

export const setPropertyVH = function () {
    const setVH = function () {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setVH();
    window.addEventListener('resize', () => {
        setVH();
    });
};

export const screenReaderSpeak = (text, {ariaLive, role}) => {
    let divEl = document.createElement('div');
    let id = 'speak-' + Date.now();
    divEl.setAttribute('id', id);
    if (ariaLive) {
        divEl.setAttribute('aria-live', ariaLive);
    }
    if (role) {
        divEl.setAttribute('role', role);
    }
    divEl.classList.add('visually-hidden');
    document.body.appendChild(divEl);

    setTimeout(function () {
        document.getElementById(id).innerHTML = text;
    }, 100);

    setTimeout(function () {
        document.body.removeChild(document.getElementById(id));
    }, 10000);
};
export const accessCookieDialog = () => {
    window.addEventListener('load', () => {
        let cookieDialog = document.querySelector('.qc-cmp2-publisher-logo-container'),
            headingDialog = cookieDialog?.querySelector('h2'),
            infoDialog = cookieDialog?.querySelector('.qc-cmp2-summary-info'),
            link = cookieDialog?.querySelector('a');

        if (cookieDialog) {
            if (cookieDialog?.offsetHeight !== 0) {
                headingDialog?.setAttribute('tabindex', '0');
                infoDialog?.setAttribute('tabindex', '0');
                screenReaderSpeak(cookieDialog.textContent, {ariaLive: 'polite', role: 'alert'});
                cookieDialog?.setAttribute('aria-live', 'assertive');
            }

            if (link?.textContent.includes('privacy')) {
                link?.classList.add('cookie-privacy');
            }
        }
    });
};

export const isSearchResultShown = () => {
    let searchResultGrid = document.querySelector('.search-result--grid'),
        url = window.location.href,
        searchResultResults = document.querySelector('.search-result__results');

    if (url.includes('collection') && !searchResultResults) {
        searchResultGrid?.classList.add('mt-n4');
    }
};

setPropertyVH();
accessCookieDialog();
isSearchResultShown();
