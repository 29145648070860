import Base from '../../base/js/base';
import {keyCodes, isOutlineStylingActive} from '../../utils/js/utils';

export default class A11y extends Base {
    navigationKeyCodes = {};

    constructor() {
        super();
        this.initialize();
    }

    initialize() {
        if (isOutlineStylingActive) {
            this.navigationKeyCodes = keyCodes;
            this.mousemoveHandlerBinded = this.mousemoveHandler.bind(this);
            this.keydownHandlerBinded = this.keydownHandler.bind(this);
            document.body.addEventListener('mousemove', this.mousemoveHandlerBinded);
        }
    }

    mousemoveHandler() {
        document.body.classList.add('mouse-user');
        document.body.removeEventListener('mousemove', this.mousemoveHandlerBinded);
        document.body.addEventListener('keydown', this.keydownHandlerBinded);
    }

    keydownHandler(event) {
        if (Object.values(this.navigationKeyCodes).includes(event.keyCode)) {
            document.body.classList.remove('mouse-user');
            document.body.removeEventListener('keydown', this.keydownHandlerBinded);
            document.body.addEventListener('mousemove', this.mousemoveHandlerBinded);
        }
    }
}
