//Twitter Overrides -----------------------------------------------------------------------------
(function (UX) {
    let tweets = {
        selectors: {
            TWITTER_FEED: '.twitter-feed--horizontal',
            TWITTER_TIMELINE: '.twitter-timeline',
        },
        bodyWidth: document.body.clientWidth,
        init: function () {
            $(tweets.selectors.TWITTER_FEED).delegate(
                tweets.selectors.TWITTER_TIMELINE,
                'DOMSubtreeModified propertychange',
                function () {
                    tweets.customizeTweetMedia();
                }
            );
            $(window).resize(function () {
                tweets.bodyWidth = document.body.clientWidth;
                tweets.customizeTweetMedia();
            });
        },
        customizeTweetMedia: function () {
            const $twitterTimeline = $(tweets.selectors.TWITTER_FEED)
                .find(tweets.selectors.TWITTER_TIMELINE)
                .contents();
            $twitterTimeline.find('.timeline-Widget').css('max-width', '100%');
            $twitterTimeline.find('.timeline-Widget .timeline-Body').css('border', 'none');
            $twitterTimeline.find('.timeline-Widget ol.timeline-TweetList').css('display', 'flex');
            $twitterTimeline.find('.timeline-Widget ol.timeline-TweetList').css('justify-content', 'space-between');
            $twitterTimeline.find('.timeline-Widget ol.timeline-TweetList').css('align-items', 'flex-start');
            $twitterTimeline.find('.timeline-Widget p.timeline-Tweet-text').css('font-size', '14px');
            $twitterTimeline.find('.timeline-Widget p.timeline-Tweet-text').css('line-height', '20px');
            $twitterTimeline.find('.timeline-Widget .TweetAuthor span').css('font-size', '16px');
            $twitterTimeline.find('.timeline-Widget li.timeline-TweetList-tweet').css('display', 'block');
            $twitterTimeline.find('.timeline-Widget li.timeline-TweetList-tweet').css('border-top', 'none');
            $twitterTimeline.find('.timeline-Widget .timeline-Tweet-media img.NaturalImage-image').css('width', '100%');
            $twitterTimeline
                .find(
                    '.timeline-Widget .timeline-LoadMore, .timeline-Widget .timeline-Header, .timeline-Widget .timeline-Footer'
                )
                .css('display', 'none');
            $twitterTimeline.find('.timeline-Widget .timeline-Viewport').css('overflow-y', 'hidden');
            $twitterTimeline.find('.timeline-Widget li.timeline-TweetList-tweet').css('width', '100%');
            $twitterTimeline.find('.timeline-Widget ol.timeline-TweetList').css('flex-direction', 'column');
            if (this.bodyWidth > UX.grid.screenSm) {
                $twitterTimeline.find('.timeline-Widget li.timeline-TweetList-tweet').css('width', '33%');
                $twitterTimeline.find('.timeline-Widget ol.timeline-TweetList').css('flex-direction', 'row');
            }
            // Call the function on dynamic updates in addition to page load
            $twitterTimeline.find('.timeline-TweetList').bind('DOMSubtreeModified propertychange', function () {
                tweets.customizeTweetMedia(this);
            });
        },
    };
    UX.tweets = tweets;
})(UX);
