import {whenAvailable} from '../../../js/_utils';
import '../widgets/cartIndicatorWidget/js/cartIndicatorWidget';
import '../widgets/purchase-options/js/purchase-options';
import '../widgets/payment/js/payment';
import '../widgets/shopping-cart/js/shopping-cart';

(function (UX) {
    $(document).ready(function () {
        whenAvailable('literatum', function () {
            literatum.Widget.prototype.updateView = function (view, model) {
                var $this = this.get();
                var $html = $(view.trim());
                if ($html.length > 0) {
                    $this.replaceWith($html);
                    this.$element = $("*[widget-id='" + $html.attr('widget-id') + "']");
                    if (this.$element.length === 0 && $html.attr('id')) {
                        this.$element = $('#' + $html.attr('id'));
                    }
                    if (this.$element.length === 0) {
                        this.$element = $("*[data-widget-id='" + $html.attr('data-widget-id') + "']");
                    }
                } else {
                    this.$element.html('');
                }
                this.registerListeners();
                this.triggerInfoHandlers(this, model);
            };
        });
    });
})(UX);
